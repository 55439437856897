import { HIDE_LOADER, SHOW_LOADER } from '../types'

const initialState = {
  loading: false,
  error: false,
}

const app = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true, error: false }
    case HIDE_LOADER:
      return { ...state, loading: false, error: true }

    default:
      return state
  }
}

export default app
