import React from 'react'
import { Languages } from '.'
import siteLogo from '../assets/logos/siteLogo.png'

const Header = () => {
  return (
    <header className='header'>
      <div className='header_languages'>
        <Languages />
      </div>
      <div className='header_logo logo pic'>
        <img src={siteLogo} alt='' />
      </div>
    </header>
  )
}

export default Header
