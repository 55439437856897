import i18n from 'i18next'
import { CHANGE_LANGUAGE } from '../types'

const _changeLanguage = newLang => ({
  type: CHANGE_LANGUAGE,
  payload: newLang,
})

export const changeLanguage = newLang => dispatch => {
  i18n.changeLanguage(newLang)
  dispatch(_changeLanguage(newLang))
}
