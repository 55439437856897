import React from 'react'
import { changeLanguage } from '../redux/actions/language'
import { useDispatch, useSelector } from 'react-redux'
import { allLanguages } from '../redux/reducers/language'
import { Link } from 'react-router-dom'

const Languages = () => {
  const selectedLanguage = useSelector(
    ({ language: { selectedLanguage } }) => selectedLanguage,
  )

  const dispatch = useDispatch()

  return (
    <div className='languages'>
      {allLanguages.map((language, i) => (
        <Link
          to=''
          key={i}
          className={
            selectedLanguage === language
              ? 'languages_link languages_link--active'
              : 'languages_link'
          }
          onClick={() => {
            dispatch(changeLanguage(language))
          }}
        >
          {language}
        </Link>
      ))}
    </div>
  )
}

export default Languages
