import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CaseItem, Loader } from '.'
import { fetchCases } from '../redux/actions/cases'

const CaseList = () => {
  const { list, loading } = useSelector(
    ({ cases: { list }, app: { loading } }) => ({
      list,
      loading,
    }),
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCases())
  }, [dispatch])

  const renderList = list => {
    let modifyList = list
      .sort(() => 0.5 - Math.random())
      .filter(
        (
          el => f =>
            !el.has(f.caseComplexity.value) && el.add(f.caseComplexity.value)
        )(new Set()),
      )

    modifyList = modifyList.sort((a, b) => {
      if (a.caseComplexity.key < b.caseComplexity.key) {
        return -1
      }
      if (a.caseComplexity.key > b.caseComplexity.key) {
        return 1
      }
      return 0
    })

    return modifyList.map(item => {
      if (item.caseComplexity.value) return <CaseItem key={item.id} {...item} />
      return null
    })
  }

  return (
    <div
      className={
        loading
          ? 'case-list _case-list case-list--loading'
          : 'case-list _case-list'
      }
    >
      {loading ? <Loader /> : renderList(list)}
    </div>
  )
}

export default CaseList
