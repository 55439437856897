import React from 'react'
import { useTranslation } from 'react-i18next'

const Description = () => {
  const { t } = useTranslation()

  return (
    <div className='description _description'>
      <div className='description_title'>
        {t('Modeling of examination and treatment of patients')}
      </div>
      <div className='description_body'>
        <div className='description_body-title'>
          {t(
            'You are a health care provider in a developed country. Several patients await your care. These patients are suspected of having Covid-19 and have been triaged by severity of illness. We recommend:',
          )}
        </div>
        <div className='description_body-items'>
          <div className='description_body-item'>
            {t(
              'Mild or Moderate case if unfamiliar with evaluation of Covid-19',
            )}
          </div>
          <div className='description_body-item'>
            {t(
              'Severe case if interested in managing severe Covid-19 in an intensive care setting',
            )}
          </div>
          <div className='description_body-item'>
            {t(
              'Critical and Expert cases if interested in managing severe Covid-19 and its complications in an intensive care setting',
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Description
