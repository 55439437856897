import React from 'react'
import { useTranslation } from 'react-i18next'
import logo1 from '../assets/logos/1.png'
import logo2 from '../assets/logos/2.png'
import logo3 from '../assets/logos/3.png'
import logo4 from '../assets/logos/4.png'
import copyrightLogo from '../assets/logos/copyright-logo.png'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className='footer '>
      <div className='footer_contact-wrapper'>
        <div className='footer_contact contact'>
          <div className='contact_title block-title'>{t('Contact us')}:</div>
          <div className='footer_contact-items contact_items'>
            <div className='footer_contact-item contact_item'>
              <div className='contact_item-title'>{t('Call us')}:</div>
              <div className='contact-item_body'>
                <a href='tel: (+994)50-271-88-55'>(+994) 51-206-38-13</a>
              </div>
            </div>
            <div className='footer_contact-item contact_item'>
              <div className='contact_item-title'>{t('Write to us')}:</div>
              <div className='contact_item-body'>
                <a href='mailto: ezgilmedical@gmail.com'>
                  ezgilmedical@gmail.com
                </a>
                <a href='mailto: info@ezgil.az'>info@ezgil.az</a>
                <a href='mailto: sales@ezgil.az'>sales@ezgil.az</a>
              </div>
            </div>
          </div>
        </div>
        <div className='footer_logos logos pic'>
          <img src={logo1} alt='' />
          <img src={logo2} alt='' />
          <img src={logo3} alt='' />
          <img src={logo4} alt='' />
        </div>
      </div>
      <div className='copyright'>
        <div className='copyright_logo pic'>
          <img src={copyrightLogo} alt='' />
        </div>
        <div className='copyright_text'>
          <span>medical technologies</span> © 2021. {t('All rights reserved')}.
        </div>
      </div>
    </footer>
  )
}

export default Footer
