import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { baseApi } from '../api'

const CaseItem = ({ id, name, intro, caseImage, caseComplexity }) => {
  const selectedLanguage = useSelector(
    ({ language: { selectedLanguage } }) => selectedLanguage,
  )

  const { t } = useTranslation()

  let caseComplexityClass = 'case-list_item case-item '

  const caseComplexityLowerCase = caseComplexity.value?.toLowerCase
    ? caseComplexity.value.toLowerCase()
    : caseComplexity

  caseComplexityClass += caseComplexityLowerCase

  return (
    <a
      href={`https://case.mdsimulyasiya.az/?id=${id}&lang=${selectedLanguage}`}
      className={caseComplexityClass}
    >
      <div className='case-item_title block-title'>
        {caseComplexity.value ? t(caseComplexity.value) : t('Empty')}
      </div>
      <div className='case-item_body'>
        {!intro[selectedLanguage] ? (
          <div className='case-item_body-desc case-item_body-desc--empty'>
            {t('Empty')}
          </div>
        ) : (
          <div
            className='case-item_body-desc'
            dangerouslySetInnerHTML={{ __html: intro[selectedLanguage] }}
          ></div>
        )}
        {caseImage && (
          <div className='case-item_body-pic pic'>
            <img
              src={`${baseApi}/api/external/public/Simulation/file/${caseImage}`}
              alt=''
            />
          </div>
        )}
        <div className='case-item_body-name'>
          <span>{name[selectedLanguage]}</span>
        </div>
      </div>
    </a>
  )
}

export default CaseItem
