import React from 'react'
import { useTranslation } from 'react-i18next'

const Content = () => {
  const { t } = useTranslation()

  return (
    <div className='content'>
      <div className='content_item'>
        <div className='content_item-title block-title'>{t('Goal')}</div>
        <div className='content_item-body'>
          <p>
            {t(
              'Once in the patient room, your task is to gather data, develop a differential diagnosis, order additional diagnostic tests, and treat the patient. Patient and disease factors and the choice and timing of treatments will impact the outcome of the illness. However, as in real life, sickness may prevail despite your best efforts.',
            )}
          </p>
          <p>
            {t(
              'You can also consult with several specialists in the simulation that may provide crucial life-saving information. Once you reach clinical stability, you will have the option to go to the call room until the end of your shift, but you may be called back if the patient deteriorates. You can also choose to conclude your shift and get feedback.',
            )}
          </p>
          <p>
            {t(
              'Consider starting with the Covid-19 Interactive Primer to learn basic concepts of managing floor patients who are suspected or confirmed of having Covid-19.',
            )}
          </p>
          <p>
            {t(
              'Each case may have different underlying diseases and outcomes if replayed. We encourage you to replay cases from this landing page, in particular critical and expert, and experience the spectrum of Covid-19 disease presentations.',
            )}
          </p>
          <p>
            {t(
              'All names and characters encountered in these simulations are purely fictional and any resemblance to real persons is unintentional.',
            )}
          </p>
          <p>
            {t(
              'Note for educators: We encourage the use of these patient encounters for synchronous or asynchronous teaching of clinical reasoning (diagnostic and management), both in groups or individually. The online nature of these cases is particularly suitable for remote learning. We hope you find these new resources to be useful in your teaching. The cases are under continuous development, any feedback, positive or negative, can be given through a survey and contacting us directly (see below).',
            )}
          </p>
          <p>
            {t('Help us by completing our survey:')}{' '}
            <a
              href='https://sorgular.az/survey/329?auth=d9b66b41-dc53-4c15-aa54-84076161f37d'
              target='_blank'
              rel='noreferrer'
            >
              {t('Covid-19 Rx Survey')}
            </a>
          </p>
        </div>
      </div>
      <div className='content_item'>
        <div className='content_item-title block-title'>{t('Credits')}</div>
        <div className='content_item-body'>
          <p>
            {t(
              'This effort is dedicated to those who work tirelessly and put themselves at personal risk to help maintain the delivery of health care.',
            )}
          </p>
        </div>
      </div>
      <div className='content_item'>
        <div className='content_item-title block-title'>
          {t('Device Support')}
        </div>
        <div className='content_item-body'>
          <p>
            {t(
              'These simulations are optimized for desktop PCs and most tablets, but mobile phones are currently not fully supported.',
            )}
          </p>
        </div>
      </div>
      <div className='content_item'>
        <div className='content_item-title block-title'>
          {t('MD Simulation Project Team')}
        </div>
        <div className='content_item-body'>
          <p className='content_item-sub-title'>
            {t('The authors of the idea of MD Simulation portal')}:
          </p>
          <p>
            {t(
              'Dr. Gahraman Hagverdiyev, Director of PHRC, Ministry of Health',
            )}
          </p>
          <p>{t('Dr. Nabil Seyidov, Ph.D., Ministry of Health, PHRC')}</p>
          <p className='content_item-sub-title'>
            {t("Head of 'MD Simulation Portal' project")}:
          </p>
          <p>{t('Dr. Nabil Seyidov, Ph.D., Ministry of Health, PHRC')}</p>
          <p className='content_item-sub-title'>
            {t('Authors of clinical cases and technical experts')}:
          </p>
          <p>{t('Dr. Nabil Seyidov, Ph.D., Ministry of Health, PHRC')}</p>
          <p>{t('Dr. Konul Ismaylova, Ministry of Health, PHRC')}</p>
          <p>{t('Leyla Eyvazova, Ministry of Health, PHRC')}</p>
          <p>{t('Khanim Eyvazova, Ministry of Health, PHRC')}</p>
          <p className='content_item-sub-title'>
            {t('Experts on clinical protocols and algorithms')}:
          </p>
          <p>{t('Dr. Shahla Ismaylova, Ministry of Health, PHRC')}</p>
          <p>{t('Dr. Shalala Hasanova, Ministry of Health, PHRC')}</p>
          <p>{t('Alina Kozlova, Project Designer')}</p>
          <p>{t('Katrin Karimova, Project Manager')}</p>
          <p>{t('Dmitry Menshaev, Chief Programmer')}</p>
          <p>{t('Teymur Gamidov, Backend programmer')}</p>
          <p>{t('Turab Huseynov, Programmer')}</p>
        </div>
      </div>
    </div>
  )
}

export default Content
