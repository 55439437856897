import { CHANGE_LANGUAGE } from '../types'

export const allLanguages = ['az', 'ru', 'en']

const initial_state = {
  selectedLanguage: 'az',
}

const language = (state = initial_state, { type, payload }) => {
  switch (type) {
    case CHANGE_LANGUAGE:
      return { ...state, selectedLanguage: payload }
    default:
      return state
  }
}

export default language
