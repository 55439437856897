import Api from '../../api'

import { hideLoader, showLoader } from './app'

import {
  FETCH_CASES_FAILURE,
  FETCH_CASES_REQUEST,
  FETCH_CASES_SUCCESS,
} from '../types'

const { getAllCases } = new Api()

const casesRequested = () => {
  return {
    type: FETCH_CASES_REQUEST,
  }
}

const casesLoaded = newCases => {
  return {
    type: FETCH_CASES_SUCCESS,
    payload: newCases,
  }
}

const casesError = () => {
  return {
    type: FETCH_CASES_FAILURE,
  }
}

const fetchCases = () => async dispatch => {
  dispatch(casesRequested())

  try {
    dispatch(showLoader())
    const response = await getAllCases()
    dispatch(casesLoaded(response))
    dispatch(hideLoader())
  } catch (error) {
    dispatch(casesError())
  }
}

export { fetchCases }
