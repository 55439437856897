import React from 'react'
import { CaseList, Content, Description, Footer, Header, RightColumn } from '.'

import '../i18n.js'

const App = () => {
  return (
    <>
      <Header />
      <div className='site-content-wrapper'>
        <main className='site-content'>
          <Description />
          <CaseList />
          <Content />
        </main>
        <RightColumn />
      </div>
      <Footer />
    </>
  )
}

export default App
