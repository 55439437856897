export const baseApi = 'https://mdsimulyasiya.az/back'

export default class Api {
  static apiBase = baseApi

  static transformCase = ({ id, value }) => {
    const { name, intro, caseImage, caseComplexity } = JSON.parse(value)
    return { id, name, intro, caseImage, caseComplexity }
  }

  getResource = async (url, method) => {
    let res

    if (method === 'POST') {
      res = await fetch(`${Api.apiBase}${url}`, {
        method,
      })
    } else {
      res = await fetch(`${Api.apiBase}${url}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    }

    if (!res.ok) {
      throw new Error(`Could not fetch ${url} received ${res.status}`)
    }
    return await res.json()
  }

  getAllCases = async () => {
    const results = await this.getResource(
      `/api/external/public/Simulation/list`,
      'POST',
    )
    return results.map(Api.transformCase)
  }
}
