import React from 'react'
import advertising1 from '../assets/img/advertising-1.jpg'
import advertising2 from '../assets/img/advertising-2.jpg'
import advertising3 from '../assets/img/advertising-3.jpg'

const RightColumn = () => {
  return (
    <aside className='right-column'>
      <div className='right-column_item advertising'>
        <div className='advertising_item pic'>
          <img src={advertising1} alt='' />
        </div>
        <div className='advertising_item pic'>
          <img src={advertising2} alt='' />
        </div>
        <div className='advertising_item pic'>
          <img src={advertising3} alt='' />
        </div>
        <div className='advertising_item pic'>
          <img src={advertising2} alt='' />
        </div>
      </div>
    </aside>
  )
}

export default RightColumn
