import {
  FETCH_CASES_FAILURE,
  FETCH_CASES_REQUEST,
  FETCH_CASES_SUCCESS,
} from '../types'

const initialState = {
  list: [],
}

const cases = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CASES_REQUEST:
      return {
        list: [],
      }

    case FETCH_CASES_SUCCESS:
      return {
        list: payload,
      }

    case FETCH_CASES_FAILURE:
      return {
        list: [],
      }

    default:
      return state
  }
}

export default cases
